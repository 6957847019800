'use client';

import css from '@/app/error.module.css';
import {ButtonLink} from '@/_components/ui-kit/ButtonLink';
import {useEffect} from 'react';
import * as Sentry from '@sentry/nextjs';

interface IProps {
	error: Error & {digest?: string};
	reset: () => void;
}

export default function Error({reset, error}: IProps) {
	useEffect(() => {
		if (process.env.NODE_ENV === 'production') {
			Sentry.captureException(error);
		}
	}, [error]);

	return (
		<div className={css.root}>
			<h2>Something went wrong!</h2>
			<ButtonLink onClick={reset}>Try again</ButtonLink>
		</div>
	);
}
