import React, {ButtonHTMLAttributes, ReactNode} from 'react';
import cn from 'classnames';

import css from './ButtonLink.module.css';

type TProps = {
	children: ReactNode;
	className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export default React.memo<TProps>(function ButtonLink({children, className, ...props}) {
	return (
		<button {...props} className={cn(css.root, className)}>
			{children}
		</button>
	);
});
